import { useState, useEffect } from "react";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";

import ExportToExcel from "components/ExportToExcel";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const ListaEstoque = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);
  const [Colonas, setColonas] = useState([]);

  const [ListaEmpresas, setListaEmpresas] = useState([]);
  const [SelectedEmpresa, setSelectedEmpresa] = useState(null);

  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [ShowListaByEmpresa, setShowListaByEmpresa] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [EmpresaId, setEmpresaId] = useState(null);

  const [SelectedTipoAcao, setSelectedTipoAcao] = useState(null);
  const [EditData, setEditData] = useState({});
  const [Quantidade, setQuantidade] = useState(null);

  const [ShowModalTransferecia, setShowModalTransferecia] = useState(false);
  const [SelectedDoEstoque, setSelectedDoEstoque] = useState(null);
  const [SelectedParaEstoque, setSelectedParaEstoque] = useState(null);
  const [SelectedProdutoTransferecia, setSelectedProdutoTransferecia] =
    useState(null);
  const [ListaProdutos, setListaProdutos] = useState([]);

  const TransfereciaProduto = async (data) => {
    await Requicicao.Patch({
      endpoint: "/EstoquePorEmpresa/Transferir",
      data,
      config: auth.GetHeaders()
    });
  };

  const TransfereciaEstoque = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      SelectedProdutoTransferecia.map(async (item) => {
        await TransfereciaProduto({
          produtoId: item.code,
          empresaExpedidoraId: SelectedDoEstoque.code,
          empresaDestinoId: SelectedParaEstoque.code,
          quantidade: item.quantidade
        });
      });
      notify({
        type: "success",
        message: "Estoque transferido com sucesso."
      });
      setShowModalTransferecia(false);
    } catch (error) {
      notify({
        type: "error",
        message: "Erro na transferecia de estoque."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const SelectEditData = (editData) => {
    const { produtoId, quantidade } = editData;
    setEditData({ produtoId, quantidade, empresaId: EmpresaId });
  };

  const OnGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      // Verifica se o objeto 'filters' está definido, senão inicializa
      const thisFilters = filters ? { ...filters } : { global: { value: null, matchMode: FilterMatchMode.CONTAINS } };
  
      // Verifica se 'global' está presente dentro de 'filters', senão inicializa
      if (!thisFilters.global) {
        thisFilters.global = { value: null, matchMode: FilterMatchMode.CONTAINS };
      }
  
      // Define o valor do filtro global
      thisFilters.global.value = value;
  
      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      produtoNome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const ClearFilter = () => {
    InitFilters();
    setSelectedEmpresa(null);
    setEmpresaId(null);
    setShowListaByEmpresa(false);
  };

  const GetAllEmpresas = async () => {
    try {
        const resposta = await Requicicao.Get({
            endpoint: "/Empresa/ObterTodasMatrizEFiliaisPorMatrizAutenticada",
            config: auth.GetHeaders()
        });
        // Adicione a opção 'Todos'
        const todasEmpresas = [
            { code: "Todos", name: "Todos" }, // Opção de selecionar todos
            ...resposta.objetoResposta.map((item) => ({
                code: item.id,
                name: `${item.codigoLojaQueEstaInserida} - ${item.apelidoOuNomeFantasia} ${item.cpfOuCnpj}`
            }))
        ];
        setListaEmpresas(todasEmpresas);
    } catch (error) {
        notify({
            type: "error",
            message: "Erro ao obter a lista de empresas."
        });
        console.error(error);
    }
};


  const GetEstoque = async () => {
    const TransformarObjeto = (objetoOriginal) => {
      const novoObjeto = [];

      objetoOriginal.forEach((produto) => {
        const novoProduto = {
          produtoId: produto.produtoId,
          produtoCodigo: produto.produtoCodigo,
          produtoNome: produto.produtoNome,
          quantidadeTotal: produto.quantidade,
        };

        produto.empresasQuantidades.forEach((empresa) => {
          novoProduto[`${empresa.empresaNome.toLowerCase()}`] =
            empresa.quantidade;
        });

        novoObjeto.push(novoProduto);
      });

      return novoObjeto;
    };

    const ObterNomesEmpresasUnicas = (objetoOriginal) => {
      const empresasUnicas = new Set();

      objetoOriginal.forEach((produto) => {
        produto.empresasQuantidades.forEach((empresa) => {
          empresasUnicas.add(empresa.empresaNome.toLowerCase());
        });
      });

      return Array.from(empresasUnicas);
    };

    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/EstoquePorEmpresa",
        config: auth.GetHeaders()
      });
      setLista(TransformarObjeto(data.objetoResposta));
      setColonas(ObterNomesEmpresasUnicas(data.objetoResposta));

    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter o estoque."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const SubmitEditarEstoque = async (event) => {
    event.preventDefault();
    try {
      if (SelectedTipoAcao !== null) {
        if (SelectedTipoAcao.code === "reposicao") {
          await Requicicao.Patch({
            endpoint: "/EstoquePorEmpresa/Repor",
            data: { ...EditData, quantidade: Quantidade },
            config: auth.GetHeaders()
          });
        }
        if (SelectedTipoAcao.code === "ajuste") {
          await Requicicao.Patch({
            endpoint: "/EstoquePorEmpresa/Ajustar",
            data: { ...EditData, quantidade: Quantidade },
            config: auth.GetHeaders()
          });
        }
        await GetEstoque();
        setShowModal(false);
        notify({
          type: "success",
          message: "Estoque atualizado com sucesso."
        });
      } else {
        notify({
          type: "aviso",
          message: "SelectedTipoAcao é nulo."
        });
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao editar o estoque."
      });
      console.error(error);
    }
  };
  
  const GetListaProdutos = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/EstoquePorEmpresa/ObterPorFilial",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.objetoResposta !== null) {
        setListaProdutos(
          data.objetoResposta.produtosQuantidades.map((item) => ({
            code: item.produtoId,

            name: item.produtoNome,
            quantidade: item.quantidade
          }))
        );
      } else {
        notify({
          type: "info",
          message: "Não há estoque para esta empresa."
        });
        setListaProdutos([]);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter o estoque da empresa."
      });
    } finally {
      setLoading(false);
    }
  };
  
  const capitalizarPalavras = (texto) =>
    texto
      .toLowerCase()
      .split(" ")
      .map((palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1))
      .join(" ");

  const formatarCabecalho = (item) => {
    const textoTruncado =
      item.length > 50 ? `${item.substring(0, 50)}...` : item;
    return capitalizarPalavras(textoTruncado);
  };

  useEffect(
    () => () => {
      if (auth.isAuthenticated()) {
        GetAllEmpresas();
        GetEstoque();
      }
    },
    [auth]
  );

  useEffect(() => {
    if (SelectedTipoAcao !== null) {
      if (SelectedTipoAcao.code === "reposicao") setQuantidade(0);
      if (SelectedTipoAcao.code === "ajuste")
        setQuantidade(EditData.quantidade);
    }
  }, [SelectedTipoAcao]);

  return (
    <div>
      <Dialog
        header="Transferir Estoque"
        visible={ShowModalTransferecia}
        modal={false}
        style={{ width: "50vw" }}
        onHide={() => setShowModalTransferecia(false)}
      >
        <form className="row" onSubmit={TransfereciaEstoque}>
          <div className="col-12">
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-3">
              <div className="w-100">
                <label htmlFor="doEstoque" className="form-label">
                  Origem
                </label>
                <Dropdown
                  id="doEstoque"
                  className="w-100"
                  value={SelectedDoEstoque}
                  onChange={(e) => {
                    GetListaProdutos(e.value.code);
                    setSelectedDoEstoque(e.value);
                  }}
                  options={ListaEmpresas}
                  optionLabel="name"
                  placeholder="Selecione um estoque"
                />
              </div>
              <div>
                <i className="bi bi-arrow-right" />
              </div>
              <div className="w-100">
                <label htmlFor="paraEstoque" className="form-label">
                  Destino
                </label>
                <Dropdown
                  id="paraEstoque"
                  className="w-100"
                  value={SelectedParaEstoque}
                  onChange={(e) => setSelectedParaEstoque(e.value)}
                  options={ListaEmpresas}
                  optionLabel="name"
                  placeholder="Selecione um estoque"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="produtos" className="form-label">
              Nome
            </label>
            <MultiSelect
              id="produtos"
              value={SelectedProdutoTransferecia}
              onChange={(e) => setSelectedProdutoTransferecia(e.value)}
              options={ListaProdutos}
              optionLabel="name"
              placeholder="Selecione produtos"
              maxSelectedLabels={3}
              className="w-100"
            />
          </div>
          <div className="col-12 mt-3">
            <DataTable
              value={SelectedProdutoTransferecia}
              stripedRows
              paginator
              rows={10}
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="Nenhum produto encontrado."
              className="small-table" // Classe para ajustar a tabela
            >
              <Column field="code" header="Código" />
              <Column field="name" header="Nome" />
              <Column
                field="quantidade"
                header="Quantidade"
                body={(rowData) => (
                  <InputNumber
                    value={rowData.quantidade}
                    onChange={(e) => {
                      const updatedProducts = SelectedProdutoTransferecia.map(
                        (product) =>
                          product.code === rowData.code
                            ? { ...product, quantidade: e.value }
                            : product
                      );

                      setSelectedProdutoTransferecia(updatedProducts);
                    }}
                  />
                )}
              />
            </DataTable>
          </div>
          <div className="col-md-12 mt-3">
            <div className="w-100 d-flex flex-row-reverse">
              <Button
                type="submit"
                label="Transferir"
                className="btn btn-primary"
              />
            </div>
          </div>
        </form>
      </Dialog>
      <Sidebar
        visible={ShowModal}
        position="right"
        onHide={() => setShowModal(false)}
      >
        <form onSubmit={SubmitEditarEstoque} className="card-body">
          <div className="row">
            <div className="col-12">
              <h5 className="mb-4">Editar de estoque</h5>
            </div>

            <div className="col-12 mb-3">
              <label htmlFor="acaoEstoque" className="form-label">
                Ação
              </label>
              <Dropdown
                id="acaoEstoque"
                className="w-100"
                value={SelectedTipoAcao}
                onChange={(e) => setSelectedTipoAcao(e.value)}
                options={[
                  {
                    code: "reposicao",
                    name: "Reposição de quantidade de estoque"
                  },
                  { code: "ajuste", name: "Ajustar a quantidade" }
                ]}
                optionLabel="name"
                placeholder="Ação"
              />
            </div>

            <div className="col-12 mb-3">
              <label htmlFor="quantidade" className="form-label">
                quantidade
              </label>
              <InputNumber
                id="quantidade"
                name="quantidade"
                className="w-100"
                useGrouping={false}
                maxFractionDigits={0}
                locale="pt-br"
                value={Quantidade}
                onChange={(e) => setQuantidade(e.value)}
              />
            </div>

            <div className="col-md-12">
              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  label="Atualizar"
                  className="btn btn-primary"
                  disabled={SelectedTipoAcao === null || Quantidade === null}
                />
              </div>
            </div>
          </div>
        </form>
      </Sidebar>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row gap-3">
            <Button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowModalTransferecia(true)}
              >
              Transferir Estoque
            </Button>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar filtro"
              outlined
              onClick={ClearFilter}
            />
          </div>
          <div className="d-flex gap-3">
            <Dropdown
              value={SelectedEmpresa}
              onChange={(e) => {
                setShowListaByEmpresa(true);
                setSelectedEmpresa(e.value);
                setEmpresaId(e.value.code); // Defina o empresaId ao selecionar a empresa
              }}
              options={ListaEmpresas}
              optionLabel="name"
              style={{ width: "300px" }}
              placeholder="Filtro por empresa"
            />
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={OnGlobalFilterChange}
                placeholder="Pesquisa Global"
              />
            </span>
          </div>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={30}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum produto encontrado."
          globalFilterFields={["produtoCodigo", "produtoNome"]}
          filters={filters}
          className="small-table" // Classe para ajustar a tabela
          sortField={SelectedEmpresa ? SelectedEmpresa.name.split(" - ")[0].toLowerCase() : "quantidadeTotal"}
          sortOrder={-1} // Ordenação decrescente
        >
          <Column field="produtoCodigo" header="Código" sortable />
          <Column field="produtoNome" header="Nome" sortable />

          {/* Renderiza a coluna quantidadeTotal apenas se SelectedEmpresa não está definido */}
          {(!SelectedEmpresa || (SelectedEmpresa && SelectedEmpresa.code === "Todos")) && (
            <Column field="quantidadeTotal" header="Quantidade" sortable />
          )}

          {Colonas.map((item) => 
            (!SelectedEmpresa || (SelectedEmpresa && SelectedEmpresa.code === "Todos")) && (
              <Column
                key={item}
                field={item}
                header={formatarCabecalho(item)}
                sortable
                body={(rowData) => rowData[item]}
              />
            )
          )}

          {Colonas.map((item) => {
            const empresaCode = SelectedEmpresa ? SelectedEmpresa.name.split(" - ")[0].toLowerCase() : "";
            return (                           
              ((SelectedEmpresa && item.toLowerCase() === empresaCode)) && (
                <Column
                  key={item}
                  field="quantidadeTotal"
                  header={formatarCabecalho(item)}
                  sortable
                  body={(rowData) => rowData[item]}
                />
              )
            )
          })}

          {/* Mostra ações apenas se não tiver empresa selecionada */}
          {SelectedEmpresa && (
            <Column
              header="Ações"
              body={(rowData) => (
                <div className="d-flex flex-row gap-3">
                  <button
                    type="button"
                    style={{ background: 'none', border: 'none', padding: 0, color: 'black', cursor: 'pointer' }}
                    aria-label="Editar"
                    onClick={() => {
                      SelectEditData(rowData);
                      setShowModal(true);
                    }}
                  >
                    <i className="pi pi-pencil" style={{ fontSize: '0.8rem' }} />
                  </button>
                </div>
              )}
            />
          )}
        </DataTable>
      </div> 
    </div> 
  );
};

export default ListaEstoque;
